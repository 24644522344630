@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  color: #037281;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #5ce5f7 rgba(255, 255, 255, 0.1);
}
*::-webkit-scrollbar {
  width: 0.5rem; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #5ce5f7; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid rgba(255, 255, 255, 0.1); /* creates padding around scroll thumb */
}
main {
  height: 100vh;
  background: linear-gradient(to right top, #58edd6, #5ce5f7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.glass {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  height: 80vh;
  width: 77%;
  border-radius: 25px;
  z-index: 2;
  backdrop-filter: blur(10px);
  display: flex;
}

.circle1,
.circle2 {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.2)
  );
  width: 20rem;
  height: 20rem;
  border-radius: 50rem;
  position: absolute;
}

.circle1 {
  top: 5%;
  right: 10%;
}
.circle2 {
  bottom: 5%;
  left: 10%;
}
.dashboard {
  flex: 1;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  backdrop-filter: blur(80px);
  border-radius: 25px;
  display: flex;

  align-items: center;
  text-align: center;
  flex-direction: column;
}

.title {
  margin: 2rem 0rem;
  font-size: 2rem;
}

.dummyicon {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: grey;
}

.link {
  display: flex;
  margin: 2rem 0rem;
  font-weight: 600;
}

.link img {
  margin: 0rem 1rem;
}

.mainsection {
  flex: 2.9;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search {
  margin-top: 3rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.search button {
  position: relative;
  right: 6%;
  background: none;
  border: none;
  cursor: pointer;
}
svg {
  width: 1rem;
  color: #037281;
}

input {
  width: 60%;
  height: 3rem;
  border: hidden;
  background: linear-gradient(
    90.99deg,
    rgba(255, 255, 255, 0.8) 0.25%,
    rgba(255, 255, 255, 0.64) 100%
  );
  backdrop-filter: blur(58px);
  border-radius: 25px;
  font-weight: 600;
  padding: 1rem;
  outline: none;
}

.results {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.cards {
  width: 20rem;
  height: 15rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  backdrop-filter: blur(80px);
  border-radius: 25px;
  margin: 1rem;
  display: flex;
  padding: 1rem;
  justify-content: center;
}
